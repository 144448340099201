




























import rService from '../../services/RequestService/RequestServiceSetup';

export default {
  data: () => ({
    entries: [],
    isLoading: false,
    model: '',
    grouped: false,
    search: null,
    loadingAll: false,
  }),

  props: {
    groupedEntry: {
      type: Boolean,
      default: false,
    },
    entry: String,
  },

  methods: {
    async downloadAll() {
      const endPoint = 'user_searches/' + this.$route.meta.path + '/download';
      const newJson = {
        limit: 0,
        page: 0,
        query: '',
      };
      const name = this.$route.meta.path == 'ds' ? 'dehashed' : 'experimental';
      this.loadingAll = true;
      const res = await rService.postFile(endPoint, newJson);
      console.log('Response', res);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'search_' + name + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      this.loadingAll = false;
    },
    newValue(newVal) {
      if (newVal) {
        this.$emit('update:entry', newVal);
      }
    },
    newValueGrouped(newVal) {
      this.$emit('groupedChange', newVal);
    },
    setGroup(group) {
      this.grouped = group;
    },
    reset() {
      this.model = '';
      this.$emit('update:entry', '');
      this.grouped = false;
      this.$emit('groupedChange', false);
    },
  },

  computed: {
    items() {
      return this.entries;
    },
  },

  created() {
    if (this.entry) {
      this.model = this.entry + '';
      this.search = this.model + '';
    }
    this.grouped = this.groupedEntry;
  },

  watch: {
    search(val, oldValue) {
      if (!val) return;
      const valLower = val.toLowerCase();
      const inclusion = val && valLower.includes(oldValue) && this.entries.length < 10;
      if (inclusion && this.entries.length < 50) {
        return;
      }
      // Items have already been loaded
      // if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      rService
        .post('user_searches/' + this.$route.meta.path, {
          limit: 20,
          page: 1,
          query: val,
        })
        .then((res) => {
          this.entries = res.docs.map(function (el) {
            return el.query;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
